<template>
  <div class="Banner">
    <div v-if="onlyBanner">
      <a v-if="banners[0].url" :href="banners[0].url">
        <img :src="banners[0].imgUrl" alt="" width="100%">
      </a>
      <img v-else :src="banners[0].imgUrl" alt="" width="100%">
    </div>
    <swiper v-else class="swiper-containers" ref="mySwiper" :options="swiperOption" :auto-play="true">
      <swiper-slide v-for="(item, index) in banners" :key="index" class="swiper-item">
        <div>
          <a v-if="item.url" :href="item.url" target="_blank">
            <img :src="item.imgUrl" alt=""/>
          </a>
          <img v-else :src="item.imgUrl" alt="">
        </div>
      </swiper-slide>
      <!-- 指示点 -->
      <div class="swiper-pagination" slot="pagination"></div>
      <!-- ← →按钮 -->
      <div class="swiper-button-prev" slot="button-prev" style="color: #fff"></div>
      <div class="swiper-button-next" slot="button-next" style="color: #fff"></div>
    </swiper>
</div>
  
</template>
<script>


import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    banners: {
      type: Array
    }
  },
  data() {
    return {
      onlyBanner: false,    // !只有一张图时
      swiperOption: {
        initialSlide: 1,
        speed: 1000, //匀速时间
        loop: true,
        autoplay: this.$route.path == '/Home/Index' ? false : {
          delay: 6000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        effect: "flip",

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    imgUrl(item){
      if(item.url){
        
      }
    }
  },
  watch:{
    banners: function(news){
      if(this.banners.length == 1){
        this.onlyBanner = true;
      }
    }
  }
};
</script>
<style lang="scss">
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.Banner {
  .swiper-button-prev {
    color: #fff;
    left: 5%;
  }
  .swiper-button-next {
    color: #fff;
    right: 5%;
  }
  .swiper-pagination-bullet-active {
    background: #6b18a8!important;
    color: #fff;
  }
  .swiper-container {
    --swiper-pagination-color: #fff;
  }
  .swiper-pagination-bullets {
    bottom: 30px;
    width: 100%;
  }
  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.6;
  }
  img {
    min-width: 1200px;
    width: 100%;
  }
}
@media (max-width: 750px) {
  .Banner .swiper-button-prev,
  .Banner .swiper-button-next {
    display: none !important;
  }
  .Banner .swiper-container {
    .swiper-pagination-bullets {
      bottom: vw(20);
      width: 100%;
    }
  }
  .Banner img {
    min-width: auto;
  }
}
</style>