import request from '@/utils/request'

// !获取首页模块
export function GetActivities(params) {
  return request({
    url: '/Activity/GetActivities',
    method: 'GET',
    params
  })
}
// !剑鸣收藏
export function KeenCollect(data) {
  return request({
    url: '/Activity/Collect',
    method: 'post',
    data
  })
}
// !剑鸣取消收藏
export function KeenUnCollect(data) {
  return request({
    url: '/Activity/UnCollect',
    method: 'post',
    data
  })
}
// !剑鸣详情页
export function GetActivityDetail(params) {
  return request({
    url: '/Activity/GetActivityDetail',
    method: 'GET',
    params
  })
}
// !剑鸣报名
export function SignUp(data) {
  return request({
    url: '/Activity/SignUp',
    method: 'post',
    data
  })
}

// !剑鸣活动报名
export function SignUpClassify(data) {
  return request({
    url: '/Activity/SignUpClassify',
    method: 'post',
    data
  })
}

// !剑鸣回放、下载资料
export function GetActivityPlayback(params) {
  return request({
    url: '/Activity/GetActivityPlayback',
    method: 'GET',
    params
  })
}

// !剑鸣提交作品 
export function SubmitWorksClassify(data) {
  return request({
    url: '/Activity/SubmitWorksClassify',
    method: 'post',
    data
  })
}

// !获取作品列表
export function GetActityShow(params) {
  return request({
    url: '/Activity/GetActityShow',
    method: 'GET',
    params
  })
}

// !作品收藏接口
export function ActityShowCollect(data) {
  return request({
    url: '/Activity/ActityShowCollect',
    method: 'post',
    data
  })
}

// !作品取消收藏接口
export function UnActityShowCollect(data) {
  return request({
    url: '/Activity/UnActityShowCollect',
    method: 'post',
    data
  })
}

// !作品投票接口
export function VoteActityShow(data) {
  return request({
    url: '/Activity/VoteActityShow',
    method: 'post',
    data
  })
}

// !获取作品详情
export function GetActityShowInfo(params) {
  return request({
    url: '/Activity/GetActityShowInfo',
    method: 'GET',
    params
  })
}

// !获取投票列表
export function GetVoteList(params) {
  return request({
    url: '/Activity/GetVoteList',
    method: 'GET',
    params
  })
}

// !抽奖
export function GetPrize(data) {
  return request({
    url: '/Activity/GetPrize',
    method: 'post',
    data
  })
}



