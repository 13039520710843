<template>
  <div class="ActivityVote">
    <div v-loading="loading"></div>
    <swiper :banners="banners"></swiper>
    <div class="ActivityVote-content">
      <div class="crumbs">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="vote-content">
        <div class="vote-description" v-html="List.voteContent"></div>
        <div class="vote-list">
          <div v-if="IsData" class="noData">
            <img src="@/assets/PC/profile/listNone.png" alt="" />
          </div>
          <div class="vote-list-item" v-for="(item, index) in List.respActityVoteInfoss" :key="index" :class="clientWidth > 750 ? 'flex3' : ''">
            <div class="vote-list-item-l">
              <div class="vote-list-item-title hidden"> {{ item.title }} </div>
            </div>
            <div class="vote-list-item-r flex3">
              <div class="vote-list-item-key">
                <div class="vote-list-item-progress" :style="{width: (item.voteCount / progressWidth) * 100 + '%' }"></div>
                <div class="vote-list-item-progress-num">{{ item.voteCount }}</div>
              </div>
              <el-button v-if="!item.isVote" type="info" size="mini" icon="el-icon-position" @click="vote(item.id, index)">投票</el-button>
              <el-button v-else type="warning" size="mini" icon="el-icon-position" disabled>投票</el-button>
            </div>
          </div>
        </div>

        <div v-if="List.respActityVoteInfoss.length > 0" class="Page">
          <div v-if="clientWidth > 750" class="page tr">
            <el-pagination
              @current-change="handleCurrentChange"
              :total="List.recordCount"
              :page-size="List.pageSize"
              :current-page="1"
              background
              layout="pager, next"
              next-text="下一页"
              hide-on-single-page
            >
            </el-pagination>
          </div>
          <div v-else class="Mb-Page loadList flex0">
            <div v-if="!IsMobileList" class="loadList0" @click="loadlist">加载更多</div>
            <div v-if="clientWidth < 750 && IsMobileList && List.respActityVoteInfoss.length > 0" class="tc notDetail">
              <div>
                没有更多内容了~ 
                <span class="violet" @click="scrollTop">返回顶部</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="voteSuccess" class="shade flex0">
        <div class="shade-content">
          <img :src="alertContent.bgImg" alt="" width="100%">
          <div class="shade-body flex0">
            <div class="shade-text tc" v-html="alertContent.text"></div>
            <div class="shade-btn">
              <el-button type="info" @click="operation(alertContent.type)">{{ alertContent.buttonText }}</el-button>
            </div>
          </div>
          <i v-if="alertContent.buttonText != '抽奖'" class="el-icon-circle-close co-fff cur shade-body-close" @click="operation('cancel')"></i>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import swiper from "@/components/Swiper.vue";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { GetBanner } from '@/api/Home.js';
import { GetVoteList } from "@/api/KeenMind.js";
import { debounce, scrollTop } from '@/utils/common.js';
import { VoteActityShow, GetPrize } from "@/api/KeenMind.js";

export default {
  name: 'ActivityVote',
  data() {
    return {
      loading: true,
      banners: [], // !banner图
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
        { name: "作品投票", href: "" }
      ],
      VoteParams: {
        actityId: this.$route.query.id ? this.$route.query.id : '',
        page: 1,
        limit: 10
      },
      List: {
        respActityVoteInfoss: [],
      },
      IsData: false, // !是否有数据
      IsMobileList: false, // !移动端加载更多
      voteSuccess: false, // !弹窗展示
      alertContent: {}, // !弹窗内容
      progressWidth: 10,
      actityShowId: ''
    }
  },
  mounted() {
    // !获取banner图
    GetBanner().then( res => {
      const windowWidth = document.documentElement.clientWidth;
      if(windowWidth > 750){
        this.banners = res.data.activity;
      }else{
        this.banners = res.data.activity_m;
      }
    });
    this.GetInit();
  },
  methods: {
    GetInit(Mobile) {
      this.loading = true;
      GetVoteList(this.VoteParams).then(res => {
        if(res.status == 1) {
          if(res.data.respActityVoteInfoss.length > 0 && this.VoteParams.page == 1) {
            if(res.data.respActityVoteInfoss[0].voteCount > 10 && res.data.respActityVoteInfoss[0].voteCount < 100) {
              this.progressWidth = 100;
            } else if(res.data.respActityVoteInfoss[0].voteCount > 100 && res.data.respActityVoteInfoss[0].voteCount < 1000) {
              this.progressWidth = 1000;
            } else if(res.data.respActityVoteInfoss[0].voteCount > 1000 ) {
              this.progressWidth = 10000;
            }
          }

          if(Mobile) {
            this.List.respActityVoteInfoss = this.List.respActityVoteInfoss.concat(res.data.respActityVoteInfoss)
          } else {
            this.List = res.data
          }

          if(this.List.respActityVoteInfoss.length == 0) {
            this.IsData = true;
          } else {
            this.IsData = false;
          }

          if(this.List.respActityVoteInfoss.length >= this.List.recordCount) {
            this.IsMobileList = true
          }
        } else {
          this.$toast(res.message);
        }
        this.loading = false
      }).catch(err => {
        this.$toast(err + "");
      })
    },
    // !投票
    vote(id, index) {
      if(!this.$store.state.token) {
        this.$toast("请登录后操作");
        sessionStorage.setItem("Fence", this.$route.path + '?id=' + this.$route.query.id)
        setTimeout(()=> {
          this.$router.push('/Login');
        }, 500)
        return false;
      }
      this.actityShowId = id
      debounce(()=> {
        VoteActityShow({actityShowId: id}).then(res => {
          if (res.status === 1) {
            this.List.respActityVoteInfoss[index].isVote = true;
            this.List.respActityVoteInfoss[index].voteCount++;
            this.alertContent = {
              bgImg: require("@/assets/PC/keenMind/vote.png"),
              text: "感谢您的投票，<br>您获得了一次抽奖机会，<br>点击下方按钮即可参与抽奖！",
              buttonText: "抽奖",
              type: 'vote',
            }
            this.voteSuccess = true;
          } else {
            this.$toast(res.message)
          }
        }).catch(err => {
          this.$toast(err)
        })
      }, 1000)
    },
    // !抽奖
    operation(type) {
      if(type == "vote") {
        const data = {
          actityShowId: this.actityShowId,
          actityId: this.VoteParams.actityId
        }
        GetPrize(data).then(res => {
          if(res.status == 1) {
            if(res.data.isPrize) {
              this.alertContent = {
                bgImg: require("@/assets/PC/keenMind/win.png"),
                text: "恭喜您获得"+ res.data.prizeName,
                buttonText: "返回",
                type: 'cancel'
              }
            } else {
              this.alertContent = {
                bgImg: require("@/assets/PC/keenMind/nowin.png"),
                text: "<img src='" + require('@/assets/PC/keenMind/nowin-icon.png') +"' width='35%' style='margin-bottom: 10px'><br>很遗憾，您未中奖！",
                buttonText: "返回",
                type: 'cancel'
              }
            }
          }
        })
      } else if(type == "cancel") {
        this.voteSuccess = false
      }
    },
    // !PC分页
    handleCurrentChange(currentIndex) {
      this.VoteParams.page = currentIndex
      this.GetInit();
    },
    // !移动端夹杂更多
    loadlist() {
      this.VoteParams.page++;
      this.GetInit(true);
    },
    // !返回顶部
    scrollTop() {
      scrollTop()
    },
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
  },
  watch: {
    List:{
      handler(val) {
        if(val.respActityVoteInfoss.length > 0) {
          if(val.respActityVoteInfoss[0].voteCount == 11) {
            this.progressWidth = 100;
          } else if(val.respActityVoteInfoss[0].voteCount == 101) {
            this.progressWidth = 1000;
          } else if(val.respActityVoteInfoss[0].voteCount == 1001) {
            this.progressWidth = 10000;
          }
        }
      },
      deep: true,
    },
  },
  components: {
    swiper,
    breadcrumb
  }
}
</script>

<style lang="scss">
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .ActivityVote-content {
    max-width: 1200px; margin: 0 auto;
    .el-button--warning.is-disabled {background: #909399; border-color: #909399;}
    .vote-content { width: 100%; box-shadow: 0 0 10px #ccc; border-radius: 6px; padding: 30px }
    .vote-list-item-title { max-width: 400px; }
    .vote-description { margin-bottom: 40px; }
    .vote-list-item {margin-bottom: 30px;}
    .vote-list-item-r { width: 100%; max-width: calc(100% - 450px);}
    .vote-list-item-key { width: calc(100% - 140px); background: #f8f8f8; position: relative;}
    .vote-list-item-progress { height: 30px; background: #f6eafe; }
    .vote-list-item-progress-num {position: absolute; right: 5px; top: 5px; }

    .shade-content {width: 500px;}
    .shade-body { width: 100%; height: 100%; position: absolute; top: 0; left: 0; flex-direction: column;}
    .shade-text { line-height: 2; font-size: 16px; margin-bottom: 30px; }
    .shade-body-close { position: absolute; top: 15px; right: 15px; }
  }
  @media (max-width: 750px) {
    .ActivityVote-content { 
      margin: 0 vw(30);
      .vote-content { padding: vw(30); border-radius: vw(6); margin-bottom: vw(30);}
      .vote-description { margin-bottom: vw(40); }
      .vote-list-item-title { margin-bottom: 5px; }
      .vote-list-item-r { width: 100%; max-width: 100%}
      .vote-list-item-key { width: calc(100% - 85px); }

      .shade-content {width: 90% } 
      .shade-text { line-height: 2; font-size: vw(32); margin-bottom: vw(60); padding-top: vw(30);}
    }
  }
</style>