<template>
	<div class="crumb">
		<div class="content">
			<span>您的位置：</span>
			<span class="refer_list" v-for="(item, index) in indexes" :key="index">
				<a href="javascript:void(0)" @click="toHref(item.href)">{{ item.name }}</a>
				<span v-if="index < indexes.length - 1">
					<i class="el-icon-arrow-right"></i>
				</span>
			</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Crumb",
		props: {
			refer: {
				type: Array,
			},
		},
		watch: {
			indexes(val) {
				this.refer = val;
			},
		},
		data() {
			return {
				indexes: this.refer,
			};
		},
		methods:{
			toHref(href){
				if(href){
					this.$router.push(href)
				}
			}
		}
	};
</script>

<style lang="scss">
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}

	//PC端
	.crumb {
		height: 70px;
		line-height: 70px;
		span,
		a {
			font-size: 16px;
			color: #363d42 !important;
		}

		.refer_list:last-of-type a {
			cursor: auto;
		}

		a:not(:last-child):hover {
			color: #6b18a8 !important;
			font-weight: 700;
		}

		i {
			font-size: 14px;
			margin: 0 3px;
		}
		span:last-child a{
			color: #6b18a8 !important;
			font-weight: bold;
		}
	}

	//移动端
	@media (max-width: 750px) {
		.crumb {
				height: auto;
				line-height: 1.5;
				padding: 10px 0;
			span,
			a {
				font-size: vw(24);
			}

			span i {
				font-size: vw(24);
			}
		}
	}
</style>
